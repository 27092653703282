import "./App.css";
import Login from "./pages/Login";
import Home from "./pages/Home";
import User from "./pages/User";
import React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

function App() {
  const userData = localStorage.getItem("user");
  const [user, setUser] = React.useState(
    userData ? JSON.parse(userData) : null
  );

  const onLogin = (user) => {
    setUser(user);
  };


  const router = createBrowserRouter([
    {
      path: "/home",
      element:  <Home user={user}  />,
    },
    {
      path: "/",
      element: <Login  onLogin={onLogin} />,
    },  
    {
      path: "/user",
      element:  <User user={user}/>
    },
  ]);
  



  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
