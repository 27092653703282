import * as React from "react";
import Popup from "react-popup";
import moment from "moment";
import "moment/locale/pt-br";
import { AppBar, Button, Input, Toolbar, Typography } from "@mui/material";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

moment.updateLocale("pt-br", {
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  day: ["Domingo", "Segunda", "Terca", "Quarta", "Quinta", "Sexta", "Sabado"],
});
const localizer = momentLocalizer(moment);

export default function Home(props) {
  const [events, setEvents] = React.useState();
  const { user } = props;
  const navigate = useNavigate();


  const handleUserButtonClick = () => {
    navigate(`/user`);

  };

  const onLogout = React.useCallback(() => {
    localStorage.removeItem("user");
    navigate("/");
  }, [navigate]);


  const fetchEvents = React.useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/events`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (response.ok) {
        const events = await response.json();
        setEvents(
          events.map((v) => ({
            ...v,
            start: moment(v.start).toDate(),
            end: moment(v.end).toDate(),
          }))
        );
      } else {
        if (response.status === 401 || response.status === 403) {
          return onLogout();
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Erro ao buscar agendamentos, tente novamente.");
    }
  }, [user, onLogout]);

  //RENDER SINGLE EVENT POPUP CONTENT
  const renderEventContent = (slotInfo) => {
    const date = moment(slotInfo.start).format("MMMM D, YYYY HH:mm");
    const timeEnd = moment(slotInfo.end).format("HH:mm");
    return (
      <>
        <div>
          <p>
            <b>Date:</b> {date} - {timeEnd}
          </p>
          <p>
            <b>Email:</b> {slotInfo.email}
          </p>
          <p>
            <b>Descrição:</b> {slotInfo.event_description}
          </p>
          <p>
            <b>Convidados:</b> {slotInfo?.guest?.replace(/,/g, " ")}
          </p>
        </div>
      </>
    );
  };

  //ON SELECT EVENT HANDLER FUNCTION
  const onSelectEventHandler = (slotInfo) => {
    const buttons = [];

    if (slotInfo.id_user === props.user.id_user) {
      buttons.push(
        {
          text: "Editar",
          className: "info",
          action: () => {
            Popup.close(); //CLOSE PREVIOUS POPUP
            openPopupForm(slotInfo); //OPEN NEW EDIT POPUP
          },
        },
        {
          text: "Deletar",
          className: "danger",
          action: async () => {
            try {
              //CALL EVENT DELETE ACTION
              const response = await fetch(
                `${process.env.REACT_APP_API_URL}/events/${slotInfo.id}`,
                {
                  method: "DELETE",
                  headers: {
                    Authorization: `Bearer ${props.user.token}`,
                  },
                }
              );
              if (!response.ok) {
                throw new Error("API call failed");
              }
              fetchEvents();
              toast.success("Evento deletado com sucesso");

              //  deleteEvent(slotInfo.id);
              Popup.close();
            } catch (error) {
              toast.error("Falha ao deletar");
            }
          },
        }
      );
    }

    Popup.create({
      title: slotInfo.name,
      content: renderEventContent(slotInfo),
      buttons: {
        right: buttons,
      },
    });
  };

  //HANDLE FUNCTION ON SELECT EVENT SLOT
  const onSelectEventSlotHandler = (slotInfo) => {
    openPopupForm(slotInfo); //OPEN POPUP FOR CREATE/EDIT EVENT
  };

  //POPUP-FORM FUNCTION FOR CREATE AND EDIT EVENT
  const openPopupForm = (slotInfo) => {
    let newEvent = false;
    let popupTitle = "Atualizar Evento";
    if (!slotInfo.hasOwnProperty("id")) {
      slotInfo.id = moment().format("x"); //Generate id with Unix Millisecond Timestamp
      slotInfo.name = props.user?.user;
      slotInfo.email = props.user?.email;
      slotInfo.guest = null;
      slotInfo.descricao = null;
      slotInfo.reason = null;
      slotInfo.timeStart = moment(slotInfo.start).format("HH:mm");
      slotInfo.timeEnd = moment(slotInfo.end).format("HH:mm");
      popupTitle = "Adicionar Evento";
      newEvent = true;
    } else {
      slotInfo.reason = slotInfo.event_description;
      slotInfo.timeStart = moment(slotInfo.start).format("HH:mm");
      slotInfo.timeEnd = moment(slotInfo.end).format("HH:mm");
    }

    const onChangeGuest = (event) => {
      slotInfo.guest = event.target.value;
    };

    const onChangeReason = (event) => {
      slotInfo.reason = event.target.value;
    };

    const onChangeTimeStart = (event) => {
      slotInfo.timeStart = event.target.value;
    };

    const onChangeTimeEnd = (event) => {
      slotInfo.timeEnd = event.target.value;
    };

    Popup.create({
      title: popupTitle,
      content: (
        <div>
          <Typography>Nome</Typography>
          <Input
            placeholder="Nome"
            defaultValue={slotInfo.name}
            className="mm-popup__input"
            // value={name}
            type="text"
            disabled={true}
          />
          <Typography>Email</Typography>
          <Input
            placeholder="Exemplo@email.com.br"
            defaultValue={slotInfo.email}
            className="mm-popup__input"
            // value={email}
            type="email"
            disabled={true}
          />
          <Typography>Adicionar convidados</Typography>
          <small>(Quando houver mais de um email, separar por " , ").</small>
          <Input
            placeholder="Exemplo@email.com.br"
            defaultValue={slotInfo.guest}
            className="mm-popup__input"
            onChange={onChangeGuest}
            // value={email}
            type="email"
          />
          <Typography>Descricao</Typography>
          <Input
            placeholder="Ex: Gravacao"
            defaultValue={slotInfo.reason}
            className="mm-popup__input"
            onChange={onChangeReason}
            // value={reason}
            type="text"
          />
          <Typography>
            Horário ({moment(slotInfo.start).format("MMMM D, YYYY")})
          </Typography>
          <Input
            onChange={onChangeTimeStart}
            // value={timeStart}
            type="time"
            placeholder="Inicio"
            defaultValue={slotInfo.timeStart}
            className="mm-popup__input"
            sx={{ width: 100 }}
          />
          <Input
            onChange={onChangeTimeEnd}
            // value={timeEnd}
            type="time"
            placeholder="Término"
            defaultValue={slotInfo.timeEnd}
            className="mm-popup__input"
            sx={{ width: 100, ml: 2 }}
          />
        </div>
      ),
      buttons: {
        left: [
          {
            text: "Cancelar",
            className: "success",
            action: () => {
              Popup.close();
            },
          },
        ],

        right: [
          {
            text: "Salvar",
            className: "success",
            action: async () => {
              //CHECK THE ID PROPERTY FOR CREATE/UPDATE
              try {
                const end = moment(
                  `${moment(slotInfo.start).format("YYYY-MM-DD")} ${
                    slotInfo.timeEnd
                  }:00`
                );
                const start = moment(
                  `${moment(slotInfo.start).format("YYYY-MM-DD")} ${
                    slotInfo.timeStart
                  }:00`
                );
                if (start.isAfter(end)) {
                  return toast.error(
                    "Data de inicio nao pode ser depois de data termino"
                  );
                }
                const data = {
                  eventDescription: slotInfo.reason,
                  eventGuest: slotInfo.guest,
                  eventDateStart: start.format("YYYY-MM-DD HH:mm:ss"),
                  eventDateEnd: end.format("YYYY-MM-DD HH:mm:ss"),
                };
                if (newEvent !== true) {
                  data.eventId = slotInfo.id;
                }

                const response = await fetch(
                  `${process.env.REACT_APP_API_URL}/events`,
                  {
                    method: newEvent ? "POST" : "PUT",
                    headers: {
                      "Content-Type": "application/json",
                      authorization: `Bearer ${props.user.token}`,
                    },
                    body: JSON.stringify(data),
                  }
                );
                if (!response.ok) {
                  if (response.status === 400) {
                    const error = await response.json();
                    if (error.message === "Lista de convidados inválido.") {
                      return toast.error("Lista de convidados inválida.");
                    } else if (error.message) {
                      return toast.error(
                        "Agendamento com horário já existente."
                      );
                    } else {
                      return toast.error("Campo descrição inválido.");
                    }
                  }
                  throw new Error("API call failed");
                }
                fetchEvents();
                if (newEvent === true) {
                  toast.success("Evento criado com sucesso");
                } else {
                  toast.success("Evento atualizado com sucesso");
                }
                Popup.close();
              } catch (error) {
                console.error(error);
                if (newEvent === true) {
                  toast.error("Erro ao criar evento");
                } else {
                  toast.error("Erro ao atualizar evento");
                }
              }
            },
          },
        ],
      },
    });
  };

  //EVENT STYLE GETTER FOR STYLING AN EVENT ITEM
  const eventStyleGetter = (event, start, end, isSelected) => {
    let current_time = moment().format("YYYY MM DD");
    let event_time = moment(event.start).format("YYYY MM DD");
    let background = current_time > event_time ? "#696969" : "#F0BE00";
    return {
      style: {
        backgroundColor: background,
      },
    };
  };

  React.useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);
  return (
    <div className="calendar-container">
      <Box sx={{ flexGrow: 1, borderRadius: "20px" }}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "rgb(252, 192, 17)", mt: 4 }}
        >
          <Toolbar className="styleBar3">
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1, fontWeight: "bold" }}
            >
              Agenda Estúdio Brazil Journal
            </Typography>
            {(user.email === "bela.baeta@braziljournal.com" || user.email === "natalia@pmovil.com.br" || user.email === "cleilson@pmovil.com.br") &&(
  <Button className="buttonStyle " onClick={handleUserButtonClick}>
  Usuarios
</Button>
            )}
          
            <Button className="buttonStyle " onClick={onLogout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Calendar
        messages={{
          week: "Semana",
          work_week: "Semana de trabalho",
          day: "Dia",
          month: "Mes",
          previous: "Anterior",
          next: "Proximo",
          today: "Hoje",
          agenda: "Agenda",

          showMore: (total) => `+${total} mais`,
        }}
        popup
        selectable
        localizer={localizer}
        defaultView={Views.DAY}
        style={{ height: "100vh" }}
        events={events}
        timeslots={2}
        titleAccessor={function (e) {
          return e.event_description;
        }}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={(slotInfo) => onSelectEventHandler(slotInfo)}
        onSelectSlot={(slotInfo) => onSelectEventSlotHandler(slotInfo)}
      />
      <Popup />
    </div>
  );
}
