import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Modal,
  Toolbar,
  Typography,
} from "@mui/material";
import * as React from "react";
import TextField from "@mui/material/TextField";
import { green } from "@mui/material/colors";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Login(props) {
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [reset, setReset] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();


  const onClickReset = async (event) => {
    try {
      const data = { email: reset };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      handleClose(true);
      toast.success(`Nova senha foi enviada para ${reset}.`);
    } catch (error) {
      toast.error("Erro ao buscar email.");
      handleClose(true);
    } finally {
      setLoading(false);
    }
  };

  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const onChangeReset = (event) => {
    setReset(event.target.value);
  };

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const onSubmitLogin = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const data = { email, password };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok === false) {
        throw new Error(response.statusText);
      }
      const user = await response.json();
      props.onLogin(user);

      localStorage.setItem("user", JSON.stringify(user));
      navigate(`/home`);
    } catch (error) {
      toast.error("Erro ao efetuar o login.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="box">
          <Box sx={{ flexGrow: 1, borderRadius: "20px" }}>
            <AppBar
              position="static"
              sx={{ backgroundColor: "rgb(252, 192, 17)", mt: 4 }}
            >
              <Toolbar className="styleBar">
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ flexGrow: 1, fontWeight: "bold" }}
                >
                  Login Agenda Estúdio
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img
                src="/images/logobj.png"
                className="my-app-logo"
                alt="logo brazil journal"
                style={{ width: "100px", height: "100px", marginTop: "70px" }}
              />
            </Grid>
            <Grid item>
              <Typography
                //className="fontStyle"
                //variant="h6"
                component="div"
                sx={{
                  flexGrow: 1,
                  color: "white",
                  fontWeight: "bolder",
                  fontSize: "25px",
                }}
              >
                Brazil Journal
              </Typography>
            </Grid>
            <Box
              className="fieldStyle"
              component="form"
              noValidate
              onSubmit={onSubmitLogin}
            >
              <Grid item xs={12} sx={{ mt: 6 }}>
                <Typography sx={{ color: "white" }}>Email Usuario</Typography>
                <TextField
                  type="email"
                  value={email}
                  label=""
                  variant="outlined"
                  size="small"
                  onChange={onChangeEmail}
                  sx={{ background: "white", width: 300 }}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography sx={{ color: "white" }}>Password</Typography>
                <TextField
                  type="password"
                  value={password}
                  label=""
                  variant="outlined"
                  size="small"
                  onChange={onChangePassword}
                  sx={{ background: "white", width: 300 }}
                />
              </Grid>
              <Grid item sx={{ mt: 1, fontSize: 12 }}>
                <Link href="#" onClick={handleOpen}>
                  Esqueci a Senha
                </Link>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Digite seu email
                    </Typography>
                    <TextField
                      type="email"
                      value={reset}
                      label=""
                      variant="outlined"
                      size="small"
                      onChange={onChangeReset}
                      sx={{ background: "white", width: 300 }}
                    />
                    <Button
                      onClick={onClickReset}
                      sx={{
                        backgroundColor: "rgb(252, 192, 17)",
                        color: "black",
                        ml: 2,
                      }}
                    >
                      Resetar
                    </Button>
                  </Box>
                </Modal>
              </Grid>
              <Grid item>
                <Button
                  //disabled={loading}
                  // className="buttonStyle"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "rgb(252, 192, 17)",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Login
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
