import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Popup from "react-popup";
import { Button, Input } from "@mui/material";
import Container from "@mui/material/Container";

const User = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [listUser, setListUser] = React.useState([]);
  const [name, setName] = React.useState();
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const { user, onLogout } = props;


  const columns = [
    { field: "id", headerName: "Id" },
    { field: "name", headerName: "Nome", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "status", headerName: "Status"},
    {
      field: "updateButton",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => openPopupForm({name: params.row.name, email: params.row.email, id: params.row.id }, false)}
        >
          Atualizar
        </Button>
      ),
    },
    {
      field: "deleteButton",
      headerName: "Ações",
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() =>
            handleDeleteButtonClick(
              params.id,
              params?.row?.status === "Ativo" ? 0 : 1
            )
          }
        >
          {params?.row?.status === "Ativo" ? "Desativar" : "Ativar"}
        </Button>
      ),
    },
  ];

  const handleDeleteButtonClick = (id, status) => {
    userDelete(id, status);
  };

  const handleHomeButtonClick = (id) => {
    navigate(`/home`);
  };

  const userDelete = async (id, status) => {
    // setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}` + `/user/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          status,
        }),
      });
      toast.success("Status alterado com sucesso.");
      listUsers();
    } catch (error) {
      console.error(error);
      toast.error("Erro ao alterar status");
    }
  };

  useEffect(() => {
    listUsers();
  }, []);

  const listUsers = async function () {
    try {
      const result = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const data = await result.json();
      setListUser(data);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  const openPopupForm = (data, newUser = true) => {
    
    const onChangeName = (event) => {
      data.name=event.target.value;
    };
    const onChangeEmail = (event) => {
      data.email=event.target.value;
    };
    const onChangePassword = (event) => {
      data.password=event.target.value;
    };

    Popup.create({
      title: newUser === true? "Novo Usuário": "Atualizando  Usuário",
      content: (
        <div>
          <Typography>Nome</Typography>
          <Input
            placeholder="Nome"
            className="mm-popup__input"
            onChange={onChangeName}
            defaultValue={data.name}
            autoComplete="off"
            type="text"
          />
          <Typography>Email</Typography>
          <Input
            placeholder="Exemplo@email.com.br"
            onChange={onChangeEmail}
            className="mm-popup__input"
            defaultValue={data.email}
            autoComplete="off"
            type="email"
          />
{newUser === true && (
<>

<Typography>Senha</Typography>
<Input
  placeholder=""
  className="mm-popup__input"
  onChange={onChangePassword}
  defaultValue={password}
  autoComplete="off"
  type="password"
/>
</>



)}
         
        </div>
      ),
      buttons: {
        left: [
          {
            text: "Cancelar",
            className: "success",
            action: () => {
              Popup.close();
            },
          },
        ],

        right: [
          {
            text: "Salvar",
            className: "success",
            action: async () => {
              //CHECK THE ID PROPERTY FOR CREATE/UPDATE
              try {

                const response = await fetch(
                  newUser === true ? `${process.env.REACT_APP_API_URL}/signup` : `${process.env.REACT_APP_API_URL}/user/update/${data.id}` , 
                  {
                  method: newUser === true ? "POST" : "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${props.user.token}`,
                  },
                  body: JSON.stringify(data),
                });
if (response.ok){
  listUsers();
  if (newUser === true) {
    toast.success("Usuario criado com sucesso");
  } else {
    toast.success("Usuario atualizado com sucesso");
  }
  Popup.close();

}else{
  throw new Error();
}
             
              } catch (error) {
                console.error(error);
                if (newUser === true) {
                  toast.error("Erro ao criar usuario");
                } else {
                  toast.error("Erro ao atualizar usuario");
                }
              }
            },
          },
        ],
      },
    });
  };

  const handleCreateButtonClick = () => {
    openPopupForm({
       name:"",
       email:"",
       password:""
    });
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, borderRadius: "20px", mb: 3 }}>
        <AppBar
          position="static"
          sx={{ backgroundColor: "rgb(252, 192, 17)", mt: 4 }}
        >
          <Toolbar className="styleBar3">
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1, fontWeight: "bold" }}
            >
              Agenda Estúdio Brazil Journal
            </Typography>
            <Button className="buttonStyle " onClick={handleHomeButtonClick}>
              Home
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Container>
        <Button
          sx={{ mb: 2, ml: 2 }}
          variant="outlined"
          color="primary"
          onClick={handleCreateButtonClick}
        >
          Criar
        </Button>

        <div style={{ height: 'auto', width: "100%" }}>
          <DataGrid
            rows={listUser}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
          />
        </div>
        <Popup />
      </Container>
    </>
  );
};

export default User;
